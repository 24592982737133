const loader = {
  'af': () => import(/* webpackChunkName: 'lily/locales/glitch/af' */'../../../../tmp/locales/glitch/af'),
  'ar': () => import(/* webpackChunkName: 'lily/locales/glitch/ar' */'../../../../tmp/locales/glitch/ar'),
  'ast': () => import(/* webpackChunkName: 'lily/locales/glitch/ast' */'../../../../tmp/locales/glitch/ast'),
  'bg': () => import(/* webpackChunkName: 'lily/locales/glitch/bg' */'../../../../tmp/locales/glitch/bg'),
  'bn': () => import(/* webpackChunkName: 'lily/locales/glitch/bn' */'../../../../tmp/locales/glitch/bn'),
  'br': () => import(/* webpackChunkName: 'lily/locales/glitch/br' */'../../../../tmp/locales/glitch/br'),
  'ca': () => import(/* webpackChunkName: 'lily/locales/glitch/ca' */'../../../../tmp/locales/glitch/ca'),
  'ckb': () => import(/* webpackChunkName: 'lily/locales/glitch/ckb' */'../../../../tmp/locales/glitch/ckb'),
  'co': () => import(/* webpackChunkName: 'lily/locales/glitch/co' */'../../../../tmp/locales/glitch/co'),
  'cs': () => import(/* webpackChunkName: 'lily/locales/glitch/cs' */'../../../../tmp/locales/glitch/cs'),
  'cy': () => import(/* webpackChunkName: 'lily/locales/glitch/cy' */'../../../../tmp/locales/glitch/cy'),
  'da': () => import(/* webpackChunkName: 'lily/locales/glitch/da' */'../../../../tmp/locales/glitch/da'),
  'de': () => import(/* webpackChunkName: 'lily/locales/glitch/de' */'../../../../tmp/locales/glitch/de'),
  'el': () => import(/* webpackChunkName: 'lily/locales/glitch/el' */'../../../../tmp/locales/glitch/el'),
  'en': () => import(/* webpackChunkName: 'lily/locales/glitch/en' */'../../../../tmp/locales/glitch/en'),
  'eo': () => import(/* webpackChunkName: 'lily/locales/glitch/eo' */'../../../../tmp/locales/glitch/eo'),
  'es-AR': () => import(/* webpackChunkName: 'lily/locales/glitch/es-AR' */'../../../../tmp/locales/glitch/es-AR'),
  'es-MX': () => import(/* webpackChunkName: 'lily/locales/glitch/es-MX' */'../../../../tmp/locales/glitch/es-MX'),
  'es': () => import(/* webpackChunkName: 'lily/locales/glitch/es' */'../../../../tmp/locales/glitch/es'),
  'et': () => import(/* webpackChunkName: 'lily/locales/glitch/et' */'../../../../tmp/locales/glitch/et'),
  'eu': () => import(/* webpackChunkName: 'lily/locales/glitch/eu' */'../../../../tmp/locales/glitch/eu'),
  'fa': () => import(/* webpackChunkName: 'lily/locales/glitch/fa' */'../../../../tmp/locales/glitch/fa'),
  'fi': () => import(/* webpackChunkName: 'lily/locales/glitch/fi' */'../../../../tmp/locales/glitch/fi'),
  'fr': () => import(/* webpackChunkName: 'lily/locales/glitch/fr' */'../../../../tmp/locales/glitch/fr'),
  'ga': () => import(/* webpackChunkName: 'lily/locales/glitch/ga' */'../../../../tmp/locales/glitch/ga'),
  'gd': () => import(/* webpackChunkName: 'lily/locales/glitch/gd' */'../../../../tmp/locales/glitch/gd'),
  'gl': () => import(/* webpackChunkName: 'lily/locales/glitch/gl' */'../../../../tmp/locales/glitch/gl'),
  'he': () => import(/* webpackChunkName: 'lily/locales/glitch/he' */'../../../../tmp/locales/glitch/he'),
  'hi': () => import(/* webpackChunkName: 'lily/locales/glitch/hi' */'../../../../tmp/locales/glitch/hi'),
  'hr': () => import(/* webpackChunkName: 'lily/locales/glitch/hr' */'../../../../tmp/locales/glitch/hr'),
  'hu': () => import(/* webpackChunkName: 'lily/locales/glitch/hu' */'../../../../tmp/locales/glitch/hu'),
  'hy': () => import(/* webpackChunkName: 'lily/locales/glitch/hy' */'../../../../tmp/locales/glitch/hy'),
  'id': () => import(/* webpackChunkName: 'lily/locales/glitch/id' */'../../../../tmp/locales/glitch/id'),
  'io': () => import(/* webpackChunkName: 'lily/locales/glitch/io' */'../../../../tmp/locales/glitch/io'),
  'is': () => import(/* webpackChunkName: 'lily/locales/glitch/is' */'../../../../tmp/locales/glitch/is'),
  'it': () => import(/* webpackChunkName: 'lily/locales/glitch/it' */'../../../../tmp/locales/glitch/it'),
  'ja': () => import(/* webpackChunkName: 'lily/locales/glitch/ja' */'../../../../tmp/locales/glitch/ja'),
  'ka': () => import(/* webpackChunkName: 'lily/locales/glitch/ka' */'../../../../tmp/locales/glitch/ka'),
  'kab': () => import(/* webpackChunkName: 'lily/locales/glitch/kab' */'../../../../tmp/locales/glitch/kab'),
  'kk': () => import(/* webpackChunkName: 'lily/locales/glitch/kk' */'../../../../tmp/locales/glitch/kk'),
  'kn': () => import(/* webpackChunkName: 'lily/locales/glitch/kn' */'../../../../tmp/locales/glitch/kn'),
  'ko': () => import(/* webpackChunkName: 'lily/locales/glitch/ko' */'../../../../tmp/locales/glitch/ko'),
  'ku': () => import(/* webpackChunkName: 'lily/locales/glitch/ku' */'../../../../tmp/locales/glitch/ku'),
  'kw': () => import(/* webpackChunkName: 'lily/locales/glitch/kw' */'../../../../tmp/locales/glitch/kw'),
  'lt': () => import(/* webpackChunkName: 'lily/locales/glitch/lt' */'../../../../tmp/locales/glitch/lt'),
  'lv': () => import(/* webpackChunkName: 'lily/locales/glitch/lv' */'../../../../tmp/locales/glitch/lv'),
  'mk': () => import(/* webpackChunkName: 'lily/locales/glitch/mk' */'../../../../tmp/locales/glitch/mk'),
  'ml': () => import(/* webpackChunkName: 'lily/locales/glitch/ml' */'../../../../tmp/locales/glitch/ml'),
  'mr': () => import(/* webpackChunkName: 'lily/locales/glitch/mr' */'../../../../tmp/locales/glitch/mr'),
  'ms': () => import(/* webpackChunkName: 'lily/locales/glitch/ms' */'../../../../tmp/locales/glitch/ms'),
  'nl': () => import(/* webpackChunkName: 'lily/locales/glitch/nl' */'../../../../tmp/locales/glitch/nl'),
  'nn': () => import(/* webpackChunkName: 'lily/locales/glitch/nn' */'../../../../tmp/locales/glitch/nn'),
  'no': () => import(/* webpackChunkName: 'lily/locales/glitch/no' */'../../../../tmp/locales/glitch/no'),
  'oc': () => import(/* webpackChunkName: 'lily/locales/glitch/oc' */'../../../../tmp/locales/glitch/oc'),
  'pa': () => import(/* webpackChunkName: 'lily/locales/glitch/pa' */'../../../../tmp/locales/glitch/pa'),
  'pl': () => import(/* webpackChunkName: 'lily/locales/glitch/pl' */'../../../../tmp/locales/glitch/pl'),
  'pt-BR': () => import(/* webpackChunkName: 'lily/locales/glitch/pt-BR' */'../../../../tmp/locales/glitch/pt-BR'),
  'pt-PT': () => import(/* webpackChunkName: 'lily/locales/glitch/pt-PT' */'../../../../tmp/locales/glitch/pt-PT'),
  'ro': () => import(/* webpackChunkName: 'lily/locales/glitch/ro' */'../../../../tmp/locales/glitch/ro'),
  'ru': () => import(/* webpackChunkName: 'lily/locales/glitch/ru' */'../../../../tmp/locales/glitch/ru'),
  'sa': () => import(/* webpackChunkName: 'lily/locales/glitch/sa' */'../../../../tmp/locales/glitch/sa'),
  'sc': () => import(/* webpackChunkName: 'lily/locales/glitch/sc' */'../../../../tmp/locales/glitch/sc'),
  'si': () => import(/* webpackChunkName: 'lily/locales/glitch/si' */'../../../../tmp/locales/glitch/si'),
  'sk': () => import(/* webpackChunkName: 'lily/locales/glitch/sk' */'../../../../tmp/locales/glitch/sk'),
  'sl': () => import(/* webpackChunkName: 'lily/locales/glitch/sl' */'../../../../tmp/locales/glitch/sl'),
  'sq': () => import(/* webpackChunkName: 'lily/locales/glitch/sq' */'../../../../tmp/locales/glitch/sq'),
  'sr-Latn': () => import(/* webpackChunkName: 'lily/locales/glitch/sr-Latn' */'../../../../tmp/locales/glitch/sr-Latn'),
  'sr': () => import(/* webpackChunkName: 'lily/locales/glitch/sr' */'../../../../tmp/locales/glitch/sr'),
  'sv': () => import(/* webpackChunkName: 'lily/locales/glitch/sv' */'../../../../tmp/locales/glitch/sv'),
  'szl': () => import(/* webpackChunkName: 'lily/locales/glitch/szl' */'../../../../tmp/locales/glitch/szl'),
  'ta': () => import(/* webpackChunkName: 'lily/locales/glitch/ta' */'../../../../tmp/locales/glitch/ta'),
  'tai': () => import(/* webpackChunkName: 'lily/locales/glitch/tai' */'../../../../tmp/locales/glitch/tai'),
  'te': () => import(/* webpackChunkName: 'lily/locales/glitch/te' */'../../../../tmp/locales/glitch/te'),
  'th': () => import(/* webpackChunkName: 'lily/locales/glitch/th' */'../../../../tmp/locales/glitch/th'),
  'tr': () => import(/* webpackChunkName: 'lily/locales/glitch/tr' */'../../../../tmp/locales/glitch/tr'),
  'tt': () => import(/* webpackChunkName: 'lily/locales/glitch/tt' */'../../../../tmp/locales/glitch/tt'),
  'ug': () => import(/* webpackChunkName: 'lily/locales/glitch/ug' */'../../../../tmp/locales/glitch/ug'),
  'uk': () => import(/* webpackChunkName: 'lily/locales/glitch/uk' */'../../../../tmp/locales/glitch/uk'),
  'ur': () => import(/* webpackChunkName: 'lily/locales/glitch/ur' */'../../../../tmp/locales/glitch/ur'),
  'vi': () => import(/* webpackChunkName: 'lily/locales/glitch/vi' */'../../../../tmp/locales/glitch/vi'),
  'zgh': () => import(/* webpackChunkName: 'lily/locales/glitch/zgh' */'../../../../tmp/locales/glitch/zgh'),
  'zh-CN': () => import(/* webpackChunkName: 'lily/locales/glitch/zh-CN' */'../../../../tmp/locales/glitch/zh-CN'),
  'zh-HK': () => import(/* webpackChunkName: 'lily/locales/glitch/zh-HK' */'../../../../tmp/locales/glitch/zh-HK'),
  'zh-TW': () => import(/* webpackChunkName: 'lily/locales/glitch/zh-TW' */'../../../../tmp/locales/glitch/zh-TW'),
};

export { loader };

