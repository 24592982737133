
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { match as matchLocale } from '@formatjs/intl-localematcher';
import { setLocale as vanillaSetLocale } from 'locales';
import { loader } from './locale-loader';

const STORAGE_NAME = 'glitch-lily-locale';
const SETLOCALE_SESSION_ONLY = 'SETLOCALE_SESSION_ONLY';
const SETLOCALE_SAVED = 'SETLOCALE_SAVED';

const storage = window.localStorage;

const defaultLocale = process.env.DEFAULT_LOCALE || 'en';

const localeNames = Object.keys(loader);

const loadLocale = (locale) => {
  const loadFunc = loader[locale];
  if (loadFunc) {
    return loadFunc();
  }
  return Promise.reject();
};

const hasLocale = (locale) => {
  return !!loader[locale];
};

const getLocaleImpl = () => {
  if (!storage) {
    return;
  }

  const localeName = storage.getItem(STORAGE_NAME);
  if (!hasLocale(localeName)) {
    return;
  }
  return localeName;
};

const negotiateLocale = () => {
  if (!Intl || !Intl.Locale) {
    return defaultLocale;
  }

  const languagesRequested = (
    navigator && navigator.languages || [navigator.language]
  );

  const negotiated = matchLocale(
    languagesRequested,
    localeNames,
    defaultLocale
  );

  return negotiated;
};

const getLocale = () => {
  return getLocaleImpl() || negotiateLocale();
};

const saveLocale = (localeName) => {
  if (!storage) {
    return;
  }

  storage.setItem(STORAGE_NAME, localeName);
};

const setLocale = (localeName) => {
  return loadLocale(localeName)
    .then((locale) => {
      return saveLocale(localeName) ? SETLOCALE_SAVED : SETLOCALE_SESSION_ONLY;
    });
};

const useLocale = () => {
  const locale = getLocale();
  const [localeState, setLocaleState] = useState(null);

  const setLocaleAndSetState = useCallback((localeName) => {
    return setLocale(localeName)
      .then(() => setLocaleState(localeName));
  }, [setLocaleState]);

  useEffect(() => {
    loadLocale(locale)
      .then(() => setLocaleState(locale))
      .catch(() => {});
  }, [locale]);

  const obj = {
    locale: localeState,
    setLocale: setLocaleAndSetState,
  }

  return obj;
};

const LangSettingsContext = React.createContext();

export {
  localeNames,
  loadLocale,
  useLocale,
  getLocale,
  LangSettingsContext,
};
