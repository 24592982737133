/*
 * Glitch-lily notices:
 * Mastodon uses the initial_state heavily.
 * It is not useful just to lazy-load <Mastodon>, as this module will be imported
 * much earlier and initial state is evaluated even before we verify the tokens.
 * To address this, we exported getters from this module, using the commonjs
 * syntax, as ES6 does not support exporting getters.
 */
const { stateFrame } = require('lily/utils/state');
const stateLanguages = require('lily/utils/state-languages').default;

const element = document.getElementById('initial-state');
const initialState = () => element && function () {
  if (window.glitchLilyInitialStateString === element.textContent) {
    return window.glitchLilyInitialStateJson;
  }

  const result = JSON.parse(element.textContent);
  try {
    result.local_settings = JSON.parse(localStorage.getItem('mastodon-settings'));
  } catch (e) {
    result.local_settings = {};
  }

  window.glitchLilyInitialStateString = element.textContent;
  window.glitchLilyInitialStateJson = result;
  return result;
}();

const getMeta = (prop) => {
  const st = initialState();
  return st && st.meta && st.meta[prop];
}

const toExport = {
  get reduceMotion () { return getMeta('reduce_motion'); },
  get autoPlayGif () { return getMeta('auto_play_gif'); },
  get displayMedia () { return getMeta('display_media') || (getMeta('display_sensitive_media') ? 'show_all' : 'default'); },
  get expandSpoilers () { return getMeta('expand_spoilers'); },
  get unfollowModal () { return getMeta('unfollow_modal'); },
  get boostModal () { return getMeta('boost_modal'); },
  get favouriteModal () { return getMeta('favourite_modal'); },
  get deleteModal () { return getMeta('delete_modal'); },
  get me () { return getMeta('me'); },
  get searchEnabled () { return getMeta('search_enabled'); },
  get maxChars () {
    const is = initialState();
    return (is && is.max_toot_chars) || 500;
  },
  get pollLimits () {
    const is = initialState();
    return (is && is.poll_limits);
  },
  get limitedFederationMode () { return getMeta('limited_federation_mode'); },
  get repository () { return getMeta('repository'); },
  get source_url () { return getMeta('source_url'); },
  get version () { return getMeta('version'); },
  get mascot () { return getMeta('mascot'); },
  get profile_directory () { return getMeta('profile_directory'); },
  get defaultContentType () { return getMeta('default_content_type'); },
  get forceSingleColumn () { return getMeta('advanced_layout') === false; },
  get useBlurhash () { return getMeta('use_blurhash'); },
  get usePendingItems () { return getMeta('use_pending_items'); },
  get useSystemEmojiFont () { return getMeta('system_emoji_font'); },
  get showTrends () { return getMeta('trends'); },
  get disableSwiping () { return getMeta('disable_swiping'); },
  get languages () { return stateLanguages; },
  get default () { return initialState(); },
  get local_settings () { return this.default.local_settings; },
};

Object.keys(stateFrame()).forEach(key => {
  Object.defineProperty(toExport, key, {
    enumerable: true,
    get () {
      return this.default[key];
    },
  });
});

module.exports = toExport;
