
import api from 'lily/utils/api';
import {
  stateFrame,
  userProfileIntoState,
  instanceIntoState,
  mascotIntoState,
} from 'lily/utils/state';

const MOD_NAME = 'lilyAuth';

const APP_NAME = 'Glitch-lily';
const AUTH_VERSION = '1';
const REDIRECT_URI_V0 = window.location.origin;
const REDIRECT_URI = window.location.origin + '/web';
const SCOPES = 'read write';

const STORAGE_NAME = 'glitch-lily-auth';

const storage = window.localStorage;

const getFromLocalStorage = () => {
  if (!storage) {
    return {};
  }

  try {
    const state = JSON.parse(storage.getItem(STORAGE_NAME));
    return state || {};
  } catch (e) {
    return {};
  };
};

const saveToLocalStorage = (state) => {
  if (!storage) {
    return;
  }

  storage.setItem(STORAGE_NAME, JSON.stringify(state));
};

const getRedirectUri = () => {
  const version = authState.get('version');
  if (version == '1') {
    return REDIRECT_URI;
  } else {
    return REDIRECT_URI_V0;
  }
};

class AuthState {
  constructor () {
    this.state = getFromLocalStorage();
  }

  getState () {
    return this.state;
  }

  setState (state) {
    this.state = state;
    saveToLocalStorage(state);
  }

  mergeState (state) {
    this.setState({
      ...this.state, ...state
    });
  }

  get (key) {
    return this.state[key];
  }

  set (key, value) {
    this.state[key] = value;
    saveToLocalStorage(this.state);
  }
};

const authState = new AuthState();

const verifyUser = (accessToken) => {
  return api(accessToken)
    .get('/api/v1/accounts/verify_credentials');
};

const fetchInstance = () => {
  return api()
    .get('/api/v1/instance');
};

// XXX This uses Pleroma API.
const fetchMascot = (accessToken) => {
  return api(accessToken).get('/api/v1/pleroma/mascot');
};

const verifyUserAndUpdateState = () => {
  const accessToken = authState.get('accessToken');
  return verifyUser(accessToken)
    .then(userData => {
      const newState = userProfileIntoState(stateFrame(), userData, accessToken);
      return newState;
    })
    .then((newState) => {
      return fetchInstance()
        .then((data) => instanceIntoState(newState, data));
    })
    .then((st) => {
      return fetchMascot(accessToken)
        .then(data => mascotIntoState(st, data))
        .catch(() => st);
    })
    .catch(error => {
      if (error.status === 403) {
        // the token is now invalid
        setAccessToken();
      }
    });
};

/**
 * @return a Promise resolving to the initial state if the user has logged in,
 *   a rejected Promise otherwise.
 */
const initState = () => {
  const credentials = authState.getState();

  const accessToken = credentials.accessToken;
  if (accessToken) {
    return verifyUserAndUpdateState();
  }

  return Promise.reject();
}

const registerApp = (clientName, redirectUri, scopes) => {
  return api()
    .post('/api/v1/apps', {
      client_name: clientName,
      redirect_uris: redirectUri,
      scopes,
    })
    .then((data) => {
      const credentials = {
        clientId: data.client_id,
        clientSecret: data.client_secret,
        version: AUTH_VERSION,
      };
      authState.mergeState(credentials);
      console.log('App registered');
    })
    .catch(error => {
      console.warn('registration failed');
      throw error;
    });
};

const verifyCredentials = (credentials) => {
  return api()
    .post('/oauth/token', {
      client_id: authState.get('clientId'),
      client_secret: authState.get('clientSecret'),
      grant_type: 'client_credentials',
      redirect_uri: getRedirectUri(),
    });
};

const registerLily = () => {
  return Promise.resolve()
    .then(() => {
      const state = authState.getState();
      const clientId = state['clientId'];
      const clientSecret = state['clientSecret'];
      if (clientId && clientSecret) {
        // registered already, check whether it is valid
        // if valid, it will resolve; if invalid, it will reject.
        // return verifyCredentials({ clientId, clientSecret });
        return;
      }
      throw 'invalid credentials';
    })
    .catch((error) => {
      // now we know we do not have valid credentials
      console.log('error: ', error);
      return registerApp(APP_NAME, REDIRECT_URI, SCOPES);
    });
};

const setCredentials = (credentials) => {
  authState.mergeState(credentials);
};

const setAccessToken = (accessToken) => {
  authState.set('accessToken', accessToken);
};

const handleAccessToken = (accessToken) => {
  setAccessToken(accessToken);
  return verifyUserAndUpdateState();
};

const obtainUserToken = (code) => {
  if (authState.get('accessToken')) {
    return verifyUserAndUpdateState();
  }

  const clientId = authState.get('clientId');
  const clientSecret = authState.get('clientSecret');

  return api()
    .post('/oauth/token', {
      grant_type: 'authorization_code',
      client_id: clientId,
      client_secret: clientSecret,
      redirect_uri: REDIRECT_URI,
      scopes: SCOPES,
      code,
    })
    .then((data) => {
      const accessToken = data.access_token;
      setAccessToken(accessToken);
      return verifyUserAndUpdateState();
    })
    .catch(error => {
      console.warn('register app failed', error);
      authState.setState({});
    });
};

const getLoggedIn = () => {
  return !!authState.get('accessToken');
};

const logout = () => {
  const accessToken = authState.get('accessToken');

  api(accessToken)
    .post('/oauth/revoke', {
      token: accessToken,
    })
    .catch(response => {
      if (response.status === 403) {
        // The token is invalid anyway
        return;
      }
      throw response;
    })
    .then(() => {
      // empty access token
      setAccessToken();
      window.location.reload();
    })
    // other kinds of network failure, i.e the logout failed
    .catch(() => {});
};

export {
  getRedirectUri,
  registerLily,
  initState,
  obtainUserToken,
  setAccessToken,
  getLoggedIn,
  logout,
  handleAccessToken,
  authState,
};
