
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { BrowserRouter, withRouter } from 'react-router-dom';
import loadable from '@loadable/component';
import Link from 'flavours/glitch/components/link';
import { signOutLink } from 'flavours/glitch/util/backend_links'
import { getRedirectUri, registerLily, initState, authState,
         handleAccessToken, obtainUserToken } from 'lily/utils/auth';
import { useLocale, LangSettingsContext } from 'lily/utils/locale';

const Mastodon = loadable(() => import('./mastodon-storeref'));

const Fallback = () => <div>Waiting...</div>;

const LoginForm = ({ authorizeUri }) => {
  return (
    <div className="box-widget">
      <div>
        <Link href={authorizeUri} target='_self' className='column-link'>Login</Link>
      </div>
    </div>
  );
};

const [mastodonReady, resolveStore] = (() => {
  let resolve;
  const promise = new Promise((r) => resolve = r);
  return [promise, resolve];
})();

const setInitialState = (state) => {
  const elem = document.querySelector('#initial-state');
  elem.textContent = JSON.stringify(state);
};

const MastodonLoader = (props) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const refCallback = useCallback(store => {
    resolveStore(store);
  }, []);

  const handleInitialState = useCallback(s => {
    setInitialState(s);
    setTimeout(setLoggedIn(true));
  });

  const [registered, setRegistered] = useState(false);

  useEffect(() => {
    if (!loggedIn) {
      registerLily()
        .then(() => {
          console.log('registered = true');
          setRegistered(true);
        })
        .then(initState)
        .then(handleInitialState);
    }
  }, [loggedIn]);

  const path = props.location.pathname;
  const query = new URLSearchParams(props.location.search);

  const code = query.get('code');
  const tokenFromQuery = query.get('access_token');

  const localeControl = useLocale();
  const { locale, setLocale } = localeControl;

  useEffect(() => {
    if (!loggedIn && code) {
      // redirected from backend
      obtainUserToken(code).then(handleInitialState);
    }
  }, [code, loggedIn]);

  useEffect(() => {
    if (!loggedIn && tokenFromQuery && !code) {
      // redirected from backend
      handleAccessToken(tokenFromQuery).then(handleInitialState);
    }
  }, [code, tokenFromQuery, loggedIn]);

  const mastodonProps = useMemo(() => ({ locale }), [locale]);

  if (loggedIn && locale) {
    return (
      <LangSettingsContext.Provider value={localeControl}>
        <Mastodon storeRef={refCallback} childProps={mastodonProps} />
      </LangSettingsContext.Provider>
    );
  } else if (loggedIn) {
    return (
      <div>Logged in, but loading locales.</div>
    );
  } else if (registered) {
    const clientId = authState.get('clientId');
    const authorizeUri = `/oauth/authorize?client_id=${clientId}&redirect_uri=${getRedirectUri()}&response_type=code`;
    return <LoginForm authorizeUri={authorizeUri} />;
  } else {
    return <div>Waiting...</div>;
  }
};

const MastodonLoaderRouted = (props) => {
  const Wrap = withRouter(MastodonLoader);
  return (
    <BrowserRouter basename='/web'>
      <Wrap {...props} />
    </BrowserRouter>
  );
};

export { MastodonLoaderRouted as MastodonLoader, mastodonReady };
