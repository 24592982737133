
import React from 'react';
import ReactDOM from 'react-dom';
import { MastodonLoader, mastodonReady } from 'lily/components/mastodon-loader.js';

const readyCallback = () => {
  const mountNode = document.getElementById('mastodon');
  const props = JSON.parse(mountNode.getAttribute('data-props'));

  ReactDOM.render(<MastodonLoader {...props} />, mountNode);
  mastodonReady.then((store) => {
    store.dispatch(setupBrowserNotifications());
    if (process.env.NODE_ENV === 'production') {
      // avoid offline in dev mode because it's harder to debug
      require('offline-plugin/runtime').install();
      store.dispatch(registerPushNotifications.register());
    }
    perf.stop('main()');
  })
    .catch(() => {});
};

export { readyCallback };
