
const lilySaveSettings = (api, data) => {
  return api.patch('/api/v1/accounts/update_credentials', {
    pleroma_settings_store: {
      'glitch-lily': data
    }
  });
}

export {
  lilySaveSettings,
};
