
import { getLocale } from 'lily/utils/locale';
import langTags from 'lily/utils/language-tags';

const currentLocale = getLocale();
const currentIntlDisplayNames = (() => {
  try {
    return new Intl.DisplayNames(currentLocale, { type: 'language', fallback: 'none' });
  } catch (_) {
    return;
  }
})();

const currentLanguageDisplayName = code => {
  try {
    const locale = new Intl.Locale(code);
    // The browser may "normalize" the code, making it inaccurate
    if (locale.language === code) {
      return currentIntlDisplayNames.of(locale);
    } else {
      return;
    }
  } catch (_) {
    return;
  }
};

const nativeDisplayName = code => {
  try {
    const locale = new Intl.Locale(code);
    // The browser may "normalize" the code, making it inaccurate
    if (locale.language === code) {
      return (new Intl.DisplayNames(locale, { type: 'language' })).of(locale);
    } else {
      return code;
    }
  } catch (_) {
    return code;
  }
};

if (!window.glitchLilyLanguages) {
  window.glitchLilyLanguages = Object.keys(langTags)
    .map(k => [k, currentLanguageDisplayName(k) || langTags[k], nativeDisplayName(k)]);
}

const languages = window.glitchLilyLanguages;

export default languages;
